import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
    selector: 'fibaBackToTopButton',
    templateUrl: './fiba-back-to-top-button.component.html',
    styleUrls: [
        './fiba-back-to-top-button.component.css',
    ],
    animations: [
        trigger('appearInOut', [
            state('in', style({
                opacity: '0.75',
                display: 'block',
            })),
            state('out', style({
                opacity: '0',
                display: 'none',
            })),
            transition('in => out', animate('100ms ease-in-out')),
            transition('out => in', animate('100ms ease-in-out')),
        ]),
    ],
})

/**
 * Component for adding a go-to-top button to scrollable browser content
 */
export class FibaBackToTopButtonComponent {

    public animationState = 'out';
    public animationStateBottom = 'out';

    private timerID: any = null;

    public defaultStylesTop: any = {
        position: 'fixed',
        top: '78%',
        right: '-15px',
        opacity: '0.75',
    };

    public defaultStylesBottom: any = {
        position: 'fixed',
        top: '85%',
        right: '-15px',
        opacity: '0.75',
    };

    @Input() public scrollDistance = 400;

    public animate = true;

    public speed = 500;

    public acceleration = 1000;

    public currentScrollTop = 0;
    /**
     * Listens to window scroll and animates the button
     */
    @HostListener('window:scroll', [])
    public onWindowScroll() {
        if (this.isBrowser()) {
            this.animationState = this.getCurrentScrollTop() > this.scrollDistance ? 'in' : 'out';
            this.animationStateBottom = this.showScrollBottom() ? 'in' : 'out';
        }
    }

    /* Scrolls window to top  */
    public scrollTop(event: any) {
        if (!this.isBrowser()) {
            return;
        }

        event.preventDefault();
        if (this.animate) {
            this.animateScrollTop();
        } else {
            window.scrollTo(0, 0);
        }
    }

    /* Scrolls window to bottom  */
    public scrollBottom(event: any) {
        if (!this.isBrowser()) {
            return;
        }

        event.preventDefault();
        if (this.animate) {
            this.animateScrollBottom();
        } else {
            window.scrollTo(0, document.body.scrollHeight - document.body.clientHeight);
        }
    }

    /* Performs the animated scroll to top */
    public animateScrollTop() {
        if (this.timerID !== null) {
            return;
        }

        let initialSpeed = this.speed;

        this.timerID = setInterval(() => {
            window.scrollBy(0, -initialSpeed);
            initialSpeed = initialSpeed + this.acceleration;
            if (this.getCurrentScrollTop() === 0) {
                clearInterval(this.timerID);
                this.timerID = null;
            }
        }, 15);
    }

    /* Performs the animated scroll to bottom */
    public animateScrollBottom() {
        if (this.timerID !== null) {
            return;
        }

        let initialSpeed = this.speed;

        this.timerID = setInterval(() => {
            window.scrollBy(0, initialSpeed);
            initialSpeed = initialSpeed + this.acceleration;
            const scrollTop = this.getCurrentScrollTop();
            if (scrollTop === this.currentScrollTop) {
                clearInterval(this.timerID);
                this.timerID = null;
            }
            this.currentScrollTop = scrollTop;
        }, 15);
    }

    /* Get current Y scroll position */
    public getCurrentScrollTop() {
        if (typeof window.scrollY !== 'undefined' && window.scrollY >= 0) {
            return window.scrollY;
        }

        if (typeof window.screenY !== 'undefined' && window.screenY >= 0) {
            return window.screenY;
        }

        if (typeof document.body.scrollTop !== 'undefined' && document.body.scrollTop >= 0) {
            return document.body.scrollTop;
        }

        if (typeof document.documentElement.scrollTop !== 'undefined' && document.documentElement.scrollTop >= 0) {
            return document.documentElement.scrollTop;
        }

        return 0;
    }

    /* Get if should show to bottom button */
    public showScrollBottom(): boolean {
        const windowHeight = window.innerHeight;

        let scrollHeight;

        if (typeof document.body.scrollHeight !== 'undefined' && document.body.scrollHeight >= 0) {
            scrollHeight = document.body.scrollHeight;
        }

        if (typeof document.documentElement.scrollHeight !== 'undefined' && document.documentElement.scrollHeight >= 0) {
            scrollHeight = document.documentElement.scrollHeight;
        }

        const currentScrollTop = this.getCurrentScrollTop();
        const bottomScrollTop = scrollHeight - windowHeight;
        if (windowHeight + this.scrollDistance - scrollHeight < 0) {
            return currentScrollTop < bottomScrollTop;
        }
        return false;
    }

    /**
     * This check will prevent 'window' logic to be executed
     * while executing the server rendering
     */
    public isBrowser(): boolean {
        return typeof (window) !== 'undefined';
    }
}
