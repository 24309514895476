import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';


import { NationalFederationDataService } from '@fiba/data-services';
import { FibaPickerBase } from '@fiba/forms/base/fiba-picker-base';
import { NationalFederationDTO, NationalFederationListItemDTO, ThingDTO, ZoneDTO } from '@fiba/models';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
    selector: 'fibaPickerNationalFederation',
    templateUrl: './fiba-picker-national-federation.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaPickerNationalFederationComponent),
            multi: true,
        },
        NationalFederationDataService,
    ],
    host: { class: 'fiba-input' },
})
export class FibaPickerNationalFederationComponent extends FibaPickerBase<NationalFederationDTO> implements OnInit {
    @ViewChild('pickerCombobox', { static: true }) public comboBox: ComboBoxComponent;
    public listItems: ThingDTO[] = [];
    @Input() protected delay = 300;
    public simpleValue: ThingDTO;

    public _placeholder = 'Search national federations...';
    @Input()
    public set placeholder(value: string) {
        this._placeholder = value;
    }

    public _zone: ZoneDTO;
    @Input()
    public set zone(value: ZoneDTO) {
        this._zone = value;
    }

    constructor(
        protected injector: Injector,
        protected nationalFederationDataService: NationalFederationDataService,
        protected notificationService: NotificationService,) {
        super(injector);
        this.idField = 'organisationId';
    }

    public ngOnInit() {
        super.ngOnInit();
        if (this.modelId) {
            this.nationalFederationDataService.fetchNationalFederation(this.modelId).subscribe(
                (response) => {
                    this.value = response;
                    this.modelId = response ? response[this.idField] : null;
                    this.modelIdChange.emit(this.modelId);
                    this.modelChange.emit(this.value);

                },
                (error) => { },
                () => { },
            );
        }
        this.comboBox.filterChange.pipe(
            debounceTime(this.delay),
            switchMap((searchValue) => this.fetchAutocompleteObservable(searchValue))
        )
        .subscribe(
            (result) => {
                this.listItems = result;
            },
        );
    }

    public filterHandler(value) {
        this.filterList(value);
    }

    protected filterList(value: string = null) {
        this.fetchAutocompleteObservable(value).subscribe(
            (data) => {
                this.listItems = data;
            },
        );
    }

    private fetchAutocompleteObservable(value: string = null): Observable<ThingDTO[]> {
        return this.nationalFederationDataService.fetchAutoCompleteList(value, this._zone);
    }

    protected updatePickerNationalFederation(nationalFederation: ThingDTO = null) {
        if (nationalFederation) {
            this.nationalFederationDataService.fetchNationalFederation(nationalFederation.id).subscribe(
                (response) => {
                    this.value = response;
                    super.saveHandler(response);
                },
                (error) => {
                    this.notificationService.emitNotification(NotificationType.Error, `Fetch nationalFederation ${nationalFederation.id} failed`);
                },
                () => { },
            );
        } else {
            super.saveHandler(null);
        }
    }

    /**
     * Callback after value is changed. Needed when picker is initialized with existing value
     *
     * @param val
     */
    public valueChanged(val: NationalFederationDTO) {
        if (val) {
            this.simpleValue = { id: val.organisationId, name: val.displayName } as ThingDTO;

            // the list of Items must contain the new value, otherwise the
            // value change will be ignored by the combobox
            this.listItems = [this.simpleValue];
        } else {
            this.simpleValue = null;
        }
    }

    /**
     * Called when user clicks on autocomplete list item, or reset
     *
     * @param newValue ThingDTO that contains the item clicked on, or null if reset
     */
    public valueChangeHandler(newValue: ThingDTO): void {
        this.updatePickerNationalFederation(newValue);
    }

    /**
     * Called when the user clicks on the OK button of the popup
     *
     * @param item
     */
    public popupSaveHandler(item: NationalFederationListItemDTO) {
        this.simpleValue = { id: item.organisationId, name: item.name } as ThingDTO;

        this.updatePickerNationalFederation(this.simpleValue);
    }
}
